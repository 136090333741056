<template>
  <div>
    <el-container>
      <el-header>
        <el-row>
          <el-col :span="13" class="header-left">
            <div>
              <!-- GridNet智慧灯杆服务管理系统 -->
              辽宁友旺光电科技有限公司
            </div>
          </el-col>
          <el-col :span="11" class="header-right">
            
            <el-row class="header-right-all" type="flex" align="bottom">
              <el-col :span="8">
                <el-input v-model="header_input" placeholder="功能搜索"></el-input>
              </el-col>
              <el-col :span="2"><i class="el-icon-search"></i></el-col>
              <el-col :span="2"><i class="el-icon-s-order"></i></el-col>
              <el-col :span="2"><i class="el-icon-s-tools"></i></el-col>
              <el-col :span="2"><i class="el-icon-warning"></i></el-col>
              <el-col :span="2"><i class="el-icon-upload"></i></el-col>
              <el-col :span="2"><i class="el-icon-user-solid"></i></el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-header>
      <el-container class="main">
        <!-- <el-aside width="60%">Aside -->
          <el-container class="main-left">
            <el-header class="main-header">
              GridNet 智慧灯杆服务管理系统
              <!-- <div style="margin-left:160px">友旺光电</div> -->
            </el-header>
            <el-footer class="main-footer">
              <el-row>
                <el-col :span="12" class="main-content">
                  <div class="title">全向兼容</div>
                  <div class="dt-content">标准开放式通讯协议，降低第三方数据设备的兼容门槛；开放SDK降低用户二次开发成本。</div>
                </el-col>
                <el-col :span="12" class="main-content">
                  <div class="title">安全可靠</div>
                  <div class="dt-content">支持IPV6。多层级路由，复合型Vlan、VPN规则，有效建立硬件级安全屏障，有效杜绝系统非法入侵；完善的鉴权和审核机制确保设备安全和数据安全。</div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12" class="main-content">
                  <div class="title">快速部署</div>
                  <div class="dt-content">设备自识别系统，即插即用；远程下发配置部署节点，快速规模布局，升级系统更加高效；紧急事件处理更轻松及时。</div>
                </el-col>
                <el-col :span="12" class="main-content">
                  <div class="title">智能运维</div>
                  <div class="dt-content">智能运维系统实时、高效、可靠，降低人为失误和人工成本；结合工单管理降系统，快速排除线路、设备故障。</div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12" class="main-content">
                  <div class="title">权责分明</div>
                  <div class="dt-content">开放式机架结构解决方案基于设备明确权责，用户功能按需自主；高效利用电气仓空间，提高系统搭建效率。</div>
                </el-col>
                <el-col :span="12" class="main-content">
                  <div class="title">共享协同</div>
                  <div class="dt-content">丰富的数据共享机制和API接口，构建基于道路的智慧城市的数字底座，为多部门协同联动提供保障。</div>
                </el-col>
              </el-row>
            </el-footer>
          </el-container>
        <!-- </el-aside> -->
        <el-main>
          <div class="right-main-l">Smart Light Pole Service</div>
          <div class="right-main-r">Management System</div>
          <el-card class="box-card">
            <div class="cart-title">用户登录</div>
            <el-form ref="loginFormRef" :model="loginForm" :rules="loginFormRules" label-width="0px" class="login_form">
                <!-- 用户名 -->
                <el-form-item prop="username">
                    <el-input v-model="loginForm.username" prefix-icon="el-icon-user" ></el-input>
                </el-form-item>
                <!-- 密码 -->
                <el-form-item prop="password">
                    <el-input v-model="loginForm.password" prefix-icon="el-icon-lock" type='password'></el-input>
                </el-form-item>
                <!-- 按钮 -->
                <el-form-item class="btns">
                    <el-button class="login" type="primary" @click="login">登录</el-button>
                    <el-button type="info" @click="resetLoginForm">重置</el-button>
                </el-form-item>
            </el-form>
          </el-card>
        </el-main>
      </el-container>
      <!-- <el-footer class="footer" >
        @2022 youwang.com 版权所有 I 辽ICP备15006110号-1
      </el-footer> -->
      <el-footer class="footer" >
        <!-- <span class="footer-item">
          @2022 youwang.cn 版权所有 I 
        </span>
        <span class="footer-item">
          <el-link href="https://beian.miit.gov.cn" target="_blank">辽ICP备15006110号-1</el-link>          
        </span>
        <span class="footer-item">
          <el-link href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=21010402000452" target="_blank"> I <el-image src="./../../beian.png"></el-image>辽公网安备 21010402000452号</el-link>
        </span> -->


            <el-link :underline="false" style="color:white;font-size: smaller;margin-top: 20px;">@2022 youwang.cn 版权所有 </el-link>
            &nbsp;
            <el-link style="color:white;font-size: smaller;margin-top: 20px;" href="https://beian.miit.gov.cn" target="_blank">辽ICP备15006110号-1</el-link>
            &nbsp;
            <el-image style="position:relative;top:17px;" src="./../../beian.png"></el-image>
            <el-link style="color:white;font-size: smaller;margin-top: 20px;" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=21010402000452" target="_blank">辽公网安备 21010402000452号</el-link>


      </el-footer>
    </el-container>
  </div>
</template>
<script>
  export default {
    data(){
        return {
            header_input:'',
            loginForm:{
                username:'',
                password:'',
            },
            loginFormRules:{
                username:[
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                ],
                password:[
                    { required: true, message: '请输入密码', trigger: 'blur' },
                ],
            }
        }
    },
    methods:{
        // 重置登录表单
        resetLoginForm(){
            // console.log(sessionStorage.getItem('lock'))
            // sessionStorage.removeItem('lock')
            this.$refs.loginFormRef.resetFields();
        },
        login(){
            this.$refs.loginFormRef.validate(async valid=>{
                if(!valid)return;
            //     const {data:res} = await this.$http.post('index/index/login' , this.loginForm);
            //     // console.log(res)
            //     if ( res.code != 0 ) return this.$message.error('登录失败')
            //     this.$message.success('登录成功')
            //     console.log(res)
            //     window.sessionStorage.setItem('token',res.token)
            //     window.sessionStorage.setItem('name',res.name)
            //     this.$router.push('/home')
            });
        }
    }

  }
</script>
<style lang="less" scoped>
.el-link{
  color: white;
}
.el-header{
  width:100%;
  padding: 0;
  // height: 30%;
  // background-color: #003333;
}
.header-left {
  // padding: 0 0px;
  background-color: #003333;
  color: white;
  line-height: 60px;
  font-size: large;
}
.header-left{
  > div{
    margin-left: 20px;
  }
}
.header-right{
  background-color: #003333;
  color: white;
  height: 60px;
}
.main{
  background-color: #F2F2F2;
  height: 817px;
  // width: 1200px;
}
.main-left{
  width: 600px;
}
.main-header{
  width: 600px;
  font-weight: 900;
  // background-color: yellow;
  margin-top: 140px;
  margin-left: 200px;
  font-size: 30px;
}
.main-footer{
  // background-color: aqua;
  margin-left: 130px;
  margin-top: 60px;
  width: 700px;
}
footer .el-footer .main-footer{
  height: 900px;
}
.main-content{
  // background-color: #00FF00;
  width: 330px;
  height: 140px;
}

.title{
  font-weight: 900;
  margin-left: 10px;
  color: #333333;
}
.dt-content{
  font-size: small;
  margin-top: 8px;
  color: #999999;
}
.right-main-l{
  width: 300px;
  font-size: 22px;
  color: #1ABC9C;
  // background-color: #666666;
  margin-top: 220px;
  margin-left: 40px;
  font-weight: bold;
}
.right-main-r{
  width: 300px;
  font-size: 22px;
  color: #1ABC9C;
  // background-color: #666666;
  margin-left: 60px;
  font-weight: bold;
}
.cart-title{
  font-size: 28px;
  font-weight: bold;
  text-align: center;
}
// .footer{
//   // margin-top: 70px;
//   background-color: #003333;
//   color: white;
//   text-align: center;
//   line-height: 70px;  
//   height:"70px";
//   font-size: 12px;
// }
.footer{
  // margin-top: 70px;
  padding: 0;
  background-color: #003333;
  color: white;
  text-align: center;
  // line-height: 70px;  
  // height:"70px";
  font-size: 12px;
}
// .footer-item{
//   padding: 0;
//   // background-color: #003333;
//   color: white;
//   text-align: center;
//   line-height: 70px;  
//   height:"70px";
//   font-size: 12px;
// }
// .footer-item .el-link {
//   padding: 0;
//   background-color: #003333;
//   color: white;
//   text-align: center;
//   line-height: 20px;  
//   height:"70px";
//   font-size: 12px;
// }
.el-card{
  width: 340px;
  // margin-left: -60px;
  background: #F2F2F2;
  margin-top: 30px;
}
.el-form{
  margin-top: 20px;
}
.el-button--primary.is-active,
.el-button--primary:active {
background: #1ABC9C;
border-color: #1ABC9C;
color: #fff;
}

.el-button--primary:focus,
.el-button--primary:hover {
background: #1fdab4;
border-color: #1fdab4;
color: #fff;
}

.el-button--primary {
color: #FFF;
background-color: #1ABC9C;
border-color: #1ABC9C;
}
.header-right-all{
  font-size: 30px;
}
// .header-right-all{
//   > el-col{
//     > el-input{
//       width: 300px;
//     }
//   }
// }
</style>
